import React from "react"
import { StoryGroup } from "components"

const LinkMap = {}

const stories = [
  {
    title: "React Authorization Samples",
    postId: "6f5e93e601bd",
  },
  {
    title: "React ClientState Samples",
    postId: "238e1b0cadb5",
  },
  {
    title: "React CRUD Samples",
    postId: "421c82630f6f",
  },
  {
    title: "CustomHook Samples",
    postId: "af82c0e64e30",
  },
  {
    title: "Module Samples",
    postId: "16a957293635",
  },
  {
    title: "Network Samples",
    postId: "4a81680ffc05",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "React Core Samples",
  path: "react-core-samples",
}

const ReactCoreSamples = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default ReactCoreSamples
